<template>
	<section>
		<h1>Modifier package QCM</h1>
		<form @submit.prevent="updatePackage()" v-if="QCMpackage">
			<div class="input-container">
				<label for="title">Titre</label>

				<input type="text" id="title" v-model="QCMpackage.title">
			</div>
			<div >
				<label for="">Matiere</label>
				<select v-model="subject">
					<option v-for="subject in getSubjects" :key="subject.id" :value="subject.id">{{ subject.name }}</option>
				</select>
			</div>
			<div >
				<label for="">Niveau</label>
				<select v-model="level">
					<option v-for="level in getLevels" :key="level.id" :value="level.id">{{ level.name }}</option>
				</select>
			</div>
			<div>
				<label for="chapter">Chapitre</label>
				<select id="chapter" v-model="chapter">
					<optgroup v-for="sequence in getSequences()" :key="sequence.id" :label="sequence.name">
						
						<option v-for="chapter in getChapters(sequence.id)" :key="chapter.id" :value="chapter.id">
							{{ chapter.name }}
						</option>
					</optgroup>
					
					
				</select>
			</div>
			<button>Valider</button>
		</form>
		<small class="error">Ne pas oublier de renseigner le chapitre</small>
	</section>
</template>
<script>
import { getQCMQuestionPackageService, updateQCMQuestionPackageService,setChapterForAllQCMQuestionsService,validateQCMPackageService } from '../../../services/qcmQuestionPackagesServices'

export default {
	data() {
		return {
			QCMpackage: {
				title: '',
				description: '',
				questions: [],
				teacher: '',
				level: '',
				status: '',
				media: ''
			},
			chapter: null,
			subject: null,
			level: null
		}
	},

	methods: {
		async getPackage() {
			this.QCMpackage = await getQCMQuestionPackageService(this.$route.params.id)
			this.subject = this.QCMpackage.questions_data[0].subject
			this.level = this.QCMpackage.questions_data[0].level
		},
		async updatePackage() {
			await updateQCMQuestionPackageService(this.$route.params.id,this.QCMpackage)

			if (this.chapter){
				const data = {
					chapter: this.chapter,
					subject: this.subject,
					level: this.level
				}
				await setChapterForAllQCMQuestionsService(this.$route.params.id, data)
			}

			if (this.QCMpackage.status !== 'VALIDATED'){
				await validateQCMPackageService(this.$route.params.id)
			}
			this.$store.state.toast = {
				text: 'Package QCM modifié avec succès',
				status: 'success'
			}
			this.$router.go(-1)
		},

		getSequences(){
			if (!this.QCMpackage.questions_data) return []
			if (!this.$store.state.sequences) return []
			
			const sequence = this.$store.state.sequences.filter(sequence => sequence.subject === this.subject && sequence.level === this.level)
			return sequence
		},
		getChapters(sequenceId) {
			if (!this.$store.state.chapters) return []
			const chapters = this.$store.state.chapters.filter(chapter => chapter.sequence === sequenceId)

			return chapters
			
		}
	},
	computed: {
		getSubjects(){
			return this.$store.state.subjects
		},
		getLevels(){
			return this.$store.state.levels
		}	
	},


	created() {
		this.getPackage()
	}
}
</script>
