import { authenticatedApiFetch } from '../utils'

export const allQCMQuestionPackageService = async () => {
    const res = await authenticatedApiFetch('/qcm-question-packages/', { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const filteredQCMQuestionPackageService = async (filter,status='VALIDATED') => {
	
	const formatedFilter = `${filter}&status=${status}`
	const res = await authenticatedApiFetch(`/qcm-question-packages/${formatedFilter}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const createQCMQuestionPackageService = async (data) => {
	const res = await authenticatedApiFetch('/qcm-question-packages/', { method: 'POST', body: JSON.stringify(data) })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const deleteQCMQuestionPackageService = async (id) => {
	const res = await authenticatedApiFetch(`/qcm-question-packages/${id}/`, { method: 'DELETE' })
		
	return res
}


export const updateQCMQuestionPackageService = async (id, data) => {
	const res = await authenticatedApiFetch(`/qcm-question-packages/${id}/`, { method: 'PATCH', body: JSON.stringify(data) })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}

export const getQCMQuestionPackageService = async (id) => {
	const res = await authenticatedApiFetch(`/qcm-question-packages/${id}/`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const setChapterForAllQCMQuestionsService = async (id, data) => {
	const res = await authenticatedApiFetch(`/qcm-question-packages/${id}/setChapterForAllQuestions/`, { method: 'POST', body: JSON.stringify(data) })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const validateQCMPackageService = async (id) => {
	const res = await authenticatedApiFetch(`/qcm-question-packages/${id}/validate/`, { method: 'GET' })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}
